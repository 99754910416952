<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-5 min-h-screen">
    <h4
      class="capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Book Keeping / Settings
    </h4>

    <h4 class="text-xl md:text-2xl font-semibold text-dark-900">
      Book keeping Settings
    </h4>

    <div
      class="w-full mx-auto p-4 md:p-8 rounded-3xl bg-white border border-newAsh flex flex-col gap-6"
    >
      <!-- <easiTab class="max-w-2xl" :tabItems="tabs" v-model:active="activeTab" /> -->

      <div class="w-full md:w-11/12 mx-auto">
        <Category />
      </div>

      <!-- <div class="w-full mx-auto md:w-10/12">
        <Delivery v-if="activeTab === 'delivery'" />
        <Tax v-if="activeTab === 'tax'" />
      </div> -->
    </div>
  </main>
</template>

<script setup>
import Category from "@/components/BookKeeping/Settings/Category.vue";
import Delivery from "@/components/BookKeeping/Settings/Delivery.vue";
import Tax from "@/components/BookKeeping/Settings/Tax.vue";

import { onMounted, ref } from "vue";
import { getCategories } from "@/services/book-keeping/settings";

// Delivery and Tax is not being chipped currently
const activeTab = ref("product category");
const tabs = ref(["product category", "delivery", "tax"]);

onMounted(() => {
  getCategories();
});
</script>

<style></style>
