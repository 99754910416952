<template>
  <div class="flex flex-col gap-5">
    <div class="flex items-center justify-between gap-4">
      <h2 class="text-lg md:text-xl font-semibold">Product Category</h2>

      <easiButton
        @click="addModal = true"
        variant="outlined"
        size="small"
        class="gap-2"
      >
        <i class="pi pi-plus text-xs" /> Add new</easiButton
      >
    </div>

    <easiPlainTable :data="visibleData" :headers="tableHeaders">
      <template #category="slotsProp">
        <div class="flex items-center justify-between">
          <span>
            {{ slotsProp.data.name }}
          </span>
          <div class="flex items-center gap-2 md:gap-4">
            <div
              @click="handleEdit(slotsProp.data)"
              class="w-8 md:w-9 h-8 md:h-9 cursor-pointer rounded-full flex items-center justify-center bg-newAsh hover:bg-highlight flex-shrink-0"
            >
              <img src="@/assets/icons/editIcon.svg" />
            </div>
            <div
              @click="openDeleteModal(slotsProp.data)"
              class="w-8 md:w-9 h-8 md:h-9 cursor-pointer rounded-full flex items-center justify-center bg-newAsh hover:bg-highlight flex-shrink-0"
            >
              <img src="@/assets/icons/deleteIcon.svg" />
            </div>
          </div>
        </div>
      </template>
    </easiPlainTable>
  </div>

  <!-- //////  Delete Category ////// -->
  <easiAlert
    :icon="opsIcon"
    v-if="deleteModal"
    title="Delete category"
    caption="Are you sure you want to delete this category?"
  >
    <div class="pb-4 px-6 grid grid-cols-2 gap-4">
      <easiButton
        variant="outlined"
        size="medium"
        :loading="loading"
        @click="handleDelete"
        block
        >Yes, delete</easiButton
      >
      <easiButton size="medium" @click="deleteModal = false" block
        >No</easiButton
      >
    </div>
  </easiAlert>

  <!-- //////// Add Edit Category ///// -->

  <AddEditCategory :isOpen="addModal" @close="addModal = false" />
  <AddEditCategory
    :isOpen="editModal"
    @close="editModal = false"
    editMode
    :data="selectedCategory"
  />

  <!-- //////  Alert Modal ////// -->

  <easiAlert
    v-if="isAlert"
    title="Delete Category"
    caption="Are you sure you want to delete this category?"
  >
    <div class="pb-4 px-6">
      <easiButton size="medium" @click="isAlert = false" block
        >Dismiss!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import AddEditCategory from "./AddEditCategory.vue";

import opsIcon from "@/assets/icons/empty-state.svg";
import { computed, ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { deleteCategory } from "@/services/book-keeping/settings";
import { useToast } from "vue-toastification";
const store = useDataStore();
const toast = useToast();

const visibleData = computed(() => {
  const categories = store.getBookingCategories || [];
  return categories.map((el, index) => {
    return {
      sn: index + 1,
      ...el,
    };
  });
});

const tableHeaders = [
  {
    style: "width:4rem",
    title: "S/N",
    field: "sn",
    type: "",
  },
  {
    style: "",
    title: "category",
    field: "name",
    type: "category",
  },
];

const loading = ref(false);

const isAlert = ref(false);
const addModal = ref(false);
const editModal = ref(false);
const deleteModal = ref(false);

const selectedCategory = ref(null);

const openDeleteModal = (arg) => {
  selectedCategory.value = arg;
  deleteModal.value = true;
};

const handleDelete = async () => {
  if (!selectedCategory.value) return;
  try {
    loading.value = true;
    const res = await deleteCategory(selectedCategory.value._id);
    if (res.success) {
      deleteModal.value = false;
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    toast.error(error.message);
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const handleEdit = (arg) => {
  selectedCategory.value = arg;
  editModal.value = true;
};
</script>

<style></style>
